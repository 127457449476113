import React from "react"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
//geschweifte klammern heißt nur eine spezifische komponete aus dem plugin werden importiert
import Layout from "../components/layout"
import ProjectHead from "../components/project/project_head"
import ProjectGrid from "../components/project/project_grid"

const ProjectPage = ({ data }) => {
  const project = data.contentfulProject.gallery
  return (
    <Layout>
      <div
        style={{
          height: "var(--spacing-120)",
        }}
      />
      <ProjectHead
        description=""
        source={data.contentfulProject.header.gatsbyImageData}
        title={data.contentfulProject.titel}
        text={renderRichText(data.contentfulProject.text)}
      ></ProjectHead>
      <ProjectGrid source={project} />
    </Layout>
  )
}

export default ProjectPage

export const Head = () => {
  return (
    <>
      <title>AycaNoel</title>
      <meta
        name="description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
      <meta property="og:url" content="aycanoel.com" />
      <meta property="og:site_name" content="AycaNoel" />
      <meta
        property="og:description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      header {
        gatsbyImageData
      }
      titel
      text {
        raw
      }
      gallery {
        spanColumns
        aspectRatio

        caption {
          raw
        }
        image {
          gatsbyImageData
          title
          publicUrl
          file {
            contentType
          }
        }
      }
    }
    contentfulGalleryImg {
      caption {
        raw
      }
    }
  }
`
