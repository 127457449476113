import React from "react"

import * as style from "./project_head.module.sass"
import { GatsbyImage } from "gatsby-plugin-image"

const ProjectHead = ({ title, source, description, text }) => {
  return (
    <div>
      <figure>
        <div className={style.head}>
          <GatsbyImage className={style.img} alt={description} image={source} />
        </div>
      </figure>
      <h1 className={style.title}>&#47;&#47;{title}</h1>
      <div className={style.text}>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default ProjectHead
